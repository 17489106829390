import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/react-concurrent-mode",
  "date": "2019-11-09",
  "title": "REACT CONCURRENT MODE",
  "author": "admin",
  "tags": ["development", "react"],
  "featuredImage": "feature.jpg",
  "excerpt": "Regardless of all the optimizations we do in in React apps, rendering process so far has been a blocking and cannot be interrupted. With the introduction of new experimental React Concurrent Mode, rendering becomes interruptible."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Message type='info' title="Previous workarounds" content="<em>Debounce</em> and <em>throttle</em> are common workarounds to mitigate for example stutter in an input field while typing." mdxType="Message" />
    <p>{`Debounce – Only update the list after the user stops typing
Throttle – Update the list with a certain maximum frequency`}</p>
    <h2>{`Similar to git branches`}</h2>
    <p>{`Concurrent Mode is like React working on git branches.`}</p>
    <p>{`Eg:- Navigating between two screens in an app`}</p>
    <p>{`In React Concurrent Mode, we can tell React to keep showing the old screen(like another git branch), fully interactive, with an inline loading indicator. And when the new screen is ready, React can take us to it.`}</p>
    <h2>{`Concurrency`}</h2>
    <h3>{`CPU`}</h3>
    <p>{`For `}<inlineCode parentName="p">{`CPU-bound updates`}</inlineCode>{` like creating DOM nodes and running component code, concurrency means that a more urgent update can “interrupt” rendering that has already started.`}</p>
    <p>{`For `}<inlineCode parentName="p">{`IO-bound updates`}</inlineCode>{` like fetching code or data from the network, concurrency means that React can start rendering in memory even before all the data arrives, and skip showing jarring empty loading states.`}</p>
    <h3>{`Install`}</h3>
    <p>{`Install the experimental version of react and react-dom.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn add react@experimental react-dom@experimental
`}</code></pre>
    <p>{`Run your app, run your build, run your tests/type checking and make sure there are no issues.`}</p>
    <p>{`Likely issues:`}</p>
    <ul>
      <li parentName="ul">{`String Refs, Legacy Context, or findDOMNode`}</li>
      <li parentName="ul">{`All the lifecycle methods that have the `}<inlineCode parentName="li">{`unsafe_`}</inlineCode>{` prefix`}</li>
    </ul>
    <p>{`Enable Concurrent Mode:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
 
const rootElement = document.getElementById('root');
// ReactDOM.render(<App />, rootEl)
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);
`}</code></pre>
    <h2>{`Try Concurrent Mode`}</h2>
    <p>{`Concurrent Mode enables two new features:`}</p>
    <ul>
      <li parentName="ul">{`Time slicing`}</li>
      <li parentName="ul">{`Suspense for everything asynchronous`}</li>
    </ul>
    <p>{`Let’s create an app for testing out these features with create-react-app.`}</p>
    <Message type="warn" title="Experimental" content="The API for suspense is likely to change before suspense is officially released, so this is still in experimental stage." mdxType="Message" />
    <p>{`Write a function for creating an actor when an actor name is provided in the input field:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function createActor(name) {
  let status = 'pending';
  let result;
  let suspender = getActor(name).then(
    name => {
      status = 'success';
      result = name;
    },
    error => {
      status = 'error';
      result = error;
    }
  );
  return {
    read() {
      if (status === 'pending') throw suspender;
      if (status === 'error') throw result;
      if (status === 'success') return result;
    }
  };
}
`}</code></pre>
    <p>{`Let’s create a function to imitate an HTTP request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function getActor(name) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(\`Your favorite actor is \${name}!\`);
      // Uncomment below to test rejecting instead
      // reject(new Error(\`Oops! could not load message for \${name}\`))
    }, 1000);
  });
}
`}</code></pre>
    <p>{`Now we can create a form so we can use the above functions`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Form() {
  const [actor, setActor] = useState(null);
  const [startTransition, isPending] = useTransition({
    timeoutMs: 3000
  });
 
  function handleSubmit(event) {
    event.preventDefault();
    const name = event.target.elements.nameInput.value;
    startTransition(() => {
      setActor(createActor(name));
    });
  }
 
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="nameInput">Name</label>&nbsp;
        <input id="nameInput" />
        &nbsp;
        <button type="submit">Submit</button>
      </form>
      <Suspense fallback={<p>Loading...</p>}>
        <Message actor={actor} isPending={isPending} />
      </Suspense>
    </div>
  );
}
 
function Message({ actor, isPending }) {
  return (
    <p style={{ opacity: isPending ? 0.4 : 1 }}>
      {actor ? actor.read() : 'Please provide an actor'}
    </p>
  );
}
`}</code></pre>
    <p>{`Here is the full example of React concurrent mode in action:`}</p>
    <iframe src="https://codesandbox.io/embed/hungry-hodgkin-bb49s?fontsize=14" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="React Concurrent Mode" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      